import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export function Animations() {
  gsap.registerPlugin(ScrollTrigger)

  gsap.defaults({
    duration: 3,
    ease: 'none',
  })

  ScrollTrigger.defaults({
    toggleActions: 'restart pause reverse pause',
    scrub: 1,
  })

  let mediaQuery = gsap.matchMedia()

  mediaQuery.add('(min-width: 1280px)', () => {
    // Cards section background scale
    gsap.to('.cards__bg', {
      scrollTrigger: {
        trigger: '.cards',
        start: 'top center',
        end: '40% center',
      },
      scaleY: 1.2,
      scaleX: 1.52,
      rotation: 0,
      duration: 5,
      onComplete: () => {
        gsap.to('.cards__bg', {
          scrollTrigger: {
            trigger: '.cards',
            start: '60% center',
            end: 'bottom center',
          },
          scaleY: 1,
          scaleX: 1,
          rotation: 0,
          duration: 5,
        })
      },
    })
  })

  // Snowflake, star, splash scroll rotation
  gsap.to('.hero__snow', {
    scrollTrigger: {
      trigger: '.hero',
      start: 'top 80px',
    },
    rotation: 360,
  })

  gsap.to('.hero-img__star', {
    scrollTrigger: {
      trigger: '.hero',
      start: 'top 80px',
    },
    rotation: 360,
  })

  gsap.to('.systems__splash', {
    scrollTrigger: {
      trigger: '.systems',
      start: 'top bottom',
    },
    rotation: 360,
  })

  gsap.to('.cards__splash', {
    scrollTrigger: {
      trigger: '.cards',
      start: 'top bottom',
    },
    rotation: 360,
  })

  gsap.to('.pricing__star', {
    scrollTrigger: {
      trigger: '.pricing',
      start: 'top bottom',
    },
    rotation: 360,
  })

  gsap.to('.faq__splash', {
    scrollTrigger: {
      trigger: '.faq',
      start: 'top bottom',
    },
    rotation: 360,
  })

  gsap.to('.full-form__star', {
    scrollTrigger: {
      trigger: '.full-form',
      start: 'top bottom',
    },
    rotation: 360,
  })

  gsap.to('.small-form__snow', {
    scrollTrigger: {
      trigger: '.small-form',
      start: 'top bottom',
    },
    rotation: 360,
  })

  gsap.to('.main__flake', {
    scrollTrigger: {
      trigger: '.full-form',
      start: 'top bottom',
    },
    rotation: 360,
  })
}
