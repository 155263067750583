import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export function Navigation() {
  const body = document.querySelector('body')
  const mobileMenu = document.querySelector('.js-mob-menu')
  const navButtons = document.querySelectorAll('.js-nav-btn')
  const toTopBtn = document.querySelector('.js-to-top')
  const lifeCallBtn = document.querySelectorAll('.js-request-call')

  gsap.registerPlugin(ScrollToPlugin, ScrollTrigger)

  const handleMenuClose = () => {
    mobileMenu.classList.remove('isMobMenuOpen')
    body.classList.remove('isMobMenuOpen')
  }

  // Site menus(desktop/mobile) navigation
  navButtons.forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault()
      const targetId = button.getAttribute('href')
      gsap.to(window, {
        duration: 1,
        scrollTo: { y: targetId, offsetY: 50 },
      })
      handleMenuClose()
    })
  })

  // To-top btn scroll up
  toTopBtn.addEventListener('click', () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: 0,
    })
  })

  // To-top btn show/hide
  gsap.to(toTopBtn, {
    scrollTrigger: {
      trigger: toTopBtn,
      start: 'top 80%',
      toggleActions: 'restart pause reverse pause',
    },
    opacity: 1,
    ease: 'linear',
  })

  //Request life call navigation
  lifeCallBtn.forEach((button) => {
    button.addEventListener('click', () => {
      gsap.to(window, {
        duration: 1,
        scrollTo: { y: '#fullForm', offsetY: 50 },
      })
    })
  })
}
