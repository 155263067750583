export function EmailForms() {
  const forms = document.querySelectorAll('.form')
  const textInputs = document.querySelectorAll('input[type="text"]')

  forms.forEach((form) => {
    form.addEventListener('submit', function (event) {
      event.preventDefault()
      const formData = new FormData(form)
      const data = Object.fromEntries(formData.entries())

      const userNameInput = form.querySelector('[name="userName"]')
      const userEmailInput = form.querySelector('[name="userEmail"]')

      const usernamePattern = /^[a-zA-Zа-яА-ЯёЁіІїЇєЄґҐ'-\s]{2,35}$/
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      // Validate username (if exist)
      if (userNameInput) {
        userNameInput.classList.remove('is-error')

        if (!usernamePattern.test(userNameInput.value.trim())) {
          userNameInput.classList.add('is-error')
          return null
        }
      }

      userEmailInput.classList.remove('is-error')

      // Validate email
      if (!emailPattern.test(userEmailInput.value.trim())) {
        userEmailInput.classList.add('is-error')
        return null
      }

      // Add loader to submit btn
      document
        .querySelector(`.${form.getAttribute('name')} button[type="submit"]`)
        .classList.add('btn--loading')

      fetch('/emails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              'Network response was not ok ' + response.statusText
            )
          }
          return response.json()
        })
        .then((data) => {
          document
            .querySelector(`.${form.getAttribute('name')}`)
            .classList.add('is-success')
          console.log(data)
          form.reset()
        })
        .catch((error) => {
          console.error(error)
        })
    })
  })

  textInputs.forEach((input) => {
    input.addEventListener('blur', () => {
      input.classList.remove('is-error')
    })
  })
}
