export function MainScripts() {
  const body = document.querySelector('body')
  const mobileMenu = document.querySelector('.js-mob-menu')
  const menuOpenBtn = document.querySelector('.js-menu-open')
  const menuCloseBtn = document.querySelector('.js-menu-close')
  const faqBlockTop = document.querySelectorAll('.js-faq-block-top')
  const enVersionLink = document.querySelectorAll('.js-en-link')
  const uaVersionLink = document.querySelectorAll('.js-ua-link')
  const originUrl = window.location.origin

  // Mobile menu open/close
  menuOpenBtn.addEventListener('click', () => {
    handleMenuOpen()
  })

  menuCloseBtn.addEventListener('click', () => {
    if (body.classList.contains('isMobMenuOpen')) {
      handleMenuClose()
    }
  })

  const handleMenuOpen = () => {
    mobileMenu.classList.add('isMobMenuOpen')
    body.classList.add('isMobMenuOpen')
  }

  const handleMenuClose = () => {
    mobileMenu.classList.remove('isMobMenuOpen')
    body.classList.remove('isMobMenuOpen')
  }

  // FAQ block collapse
  for (const block of faqBlockTop) {
    block.addEventListener('click', (e) => {
      e.target.classList.toggle('faq-block__top--opened')
    })
  }

  enVersionLink.forEach((link) => {
    link.href = `${originUrl}`
  })

  uaVersionLink.forEach((link) => {
    link.href = `${originUrl}/ua.html`
  })
}
