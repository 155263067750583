import { Animations } from './animations'
import { UsersPriceCalc } from './calc'
import { EmailForms } from './email-forms'
import { MainScripts } from './main'
import { Navigation } from './navigation'

document.addEventListener('DOMContentLoaded', () => {
  MainScripts()

  // Users price calculator
  UsersPriceCalc()

  //Scroll animations
  Animations()

  //Site navigation
  Navigation()

  //Email forms
  EmailForms()
})
