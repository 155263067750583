export function UsersPriceCalc() {
  const priceCardToggleBtn = document.querySelector('.js-pricing-card-toggle')
  const priceCardAddModule = document.querySelector('.js-add-module')
  const priceCardExpandBlock = document.querySelector(
    '.pricing-card-list__expand'
  )
  const personsRangeSlider = document.querySelector('.js-person-range')
  const personsRangeInput = document.querySelector('.js-calc-input')
  const personsInputCounter = document.querySelector('.js-calc-input-counter')
  const calcCoreSum = document.querySelector('.js-calc-core')
  const calcAiSum = document.querySelector('.js-calc-ai')
  const calcTotalSum = document.querySelector('.js-calc-total')
  const calcInputText = document.querySelector('.calc-input__text')

  const setTotalPrice = () => (isModuleActive ? corePrice + aiPrice : corePrice)

  const defaultCorePrice = 3
  const defaultAiPrice = 2
  let corePrice = defaultCorePrice
  let aiPrice = defaultAiPrice
  let isModuleActive = false
  let totalPrice = setTotalPrice()

  // Collapse pricing card
  priceCardToggleBtn.addEventListener('click', () => {
    priceCardExpandBlock.classList.toggle('pricing-card-list__expand--expanded')
    priceCardToggleBtn.classList.toggle('pricing-card__toggle--active')
  })

  // Toggle pricing module
  priceCardAddModule.addEventListener('click', () => {
    priceCardAddModule.classList.toggle('pricing-card__btn--added')
    document
      .querySelector('.js-calc-module-item')
      .classList.toggle('calc-modules__item--visible')

    isModuleActive = !isModuleActive
    totalPrice = setTotalPrice()
    calcTotalSum.textContent = totalPrice
  })

  // Person range input (pricing calculator)
  personsRangeSlider.addEventListener('input', (e) => {
    const currentValue = Number(e.target.value)

    // display users counter in pseudo-input field
    personsInputCounter.textContent = currentValue

    // set total price value
    if (currentValue > 1) {
      corePrice = currentValue * defaultCorePrice
      aiPrice = currentValue * defaultAiPrice
      totalPrice = setTotalPrice()

      calcInputText.classList.add('calc-input__text_full')
    } else {
      corePrice = defaultCorePrice
      aiPrice = defaultAiPrice
      totalPrice = setTotalPrice()

      calcInputText.classList.remove('calc-input__text_full')
    }

    // display total price
    calcTotalSum.textContent = totalPrice
    calcCoreSum.textContent = corePrice
    calcAiSum.textContent = aiPrice

    // update persons range input value
    personsRangeInput.value = currentValue
  })

  // Persons input (pricing calculator)
  personsRangeInput.addEventListener('input', (e) => {
    const currentValue = Number(e.target.value)

    // Set/update total price
    corePrice = currentValue * defaultCorePrice
    aiPrice = currentValue * defaultAiPrice
    totalPrice = setTotalPrice()

    // display users counter in input
    calcTotalSum.textContent = totalPrice
    calcCoreSum.textContent = corePrice
    calcAiSum.textContent = aiPrice
    personsInputCounter.textContent = currentValue

    // set range slider position
    personsRangeSlider.value = currentValue

    currentValue > 1
      ? calcInputText.classList.add('calc-input__text_full')
      : calcInputText.classList.remove('calc-input__text_full')
  })
}
